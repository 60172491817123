import { ComponentEntry } from '../../core/common-types'

const entry: ComponentEntry = {
	componentType: 'TPASection',
	loadComponent: () => {
		return process.env.PACKAGE_NAME === 'thunderbolt-ds'
			? import('../TpaCommon/TPAPreviewExtension' /* webpackChunkName: "TPAPreviewExtension" */)
			: import('../TpaCommon/TPABaseComponent' /* webpackChunkName: "TPABaseComponent" */)
	},
}

export default entry
